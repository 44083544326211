<template>
<div class="tab-detail">
  <div class="tab-detail-title">联系我们</div>
  <div class="tab-detail-contact">
    <div class="contact-item">
      <i class="iconfont">&#xe75b;</i>
      <span class="item-kf">客服热线</span>
      <span class="item-phone">13338466186</span>
    </div>
    <div class="contact-item">
      <i class="iconfont">&#xe679;</i>
      <span class="item-kf">企业邮箱</span>
      <span class="item-phone">info@yikejiaoyu.com</span>
    </div>
    <div class="contact-item">
      <i class="iconfont">&#xe6c1;</i>
      <span class="item-kf">公司地址</span>
      <span class="item-phone">厦门市思明区软件园二期<br>观日路34号之三401室</span>
    </div>
  </div>
  <div class="tab-detail-map">
    <img src="./../../assets/images/about/map-tp.png" alt="">
  </div>
</div>
</template>

<script>
export default {
name: "contactUs"
}
</script>

<style scoped lang="scss">
.tab-detail{
  display: flex;
  flex-direction: column;
  height: 100%;
  .tab-detail-title{
    margin-top: 60px;
    color: #222222;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
  }
  .tab-detail-contact{
    margin-top: 74px;
    display: flex;
    justify-content: center;
    .contact-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: calc(100% / 3);
      i{
        font-size: 66px;
        color: #584EEE;
      }
      .item-kf{
        color: #666666;
        font-size: 16px;
        font-weight: bold;
        line-height: 1;
        margin: 18px 0 22px 0;
      }
      .item-phone{
        //line-height: 1;
        color: #444444;
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
      }
    }
  }
  .tab-detail-map{
    margin: 64px 0 94px 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>