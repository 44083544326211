<template>
  <div class="tab-detail">
    <div class="tab-detail-title">一课简介</div>
    <div class="tab-detail-text" style="margin-top: 64px">
      <span>厦门一课信息技术服务有限公司（简称“一课教育”）是一家集国内电商、跨境电商和新媒体电商一站式教育生态供应链平台提供商，致力与提供一站式电商/跨境电商/新媒体电商整体解决方案，是目前行业技术领先的互联网教育公司。</span>
    </div>
    <div class="tab-detail-text" style="margin-top: 30px">
      <span>一课教育围绕新电商、新教育、新技术、新服务四大环节的创新不断融合人、课程体系与工具之间的关系。一课教育专注职业院校、应用型本科领域，通过互联网技术与混合式学习等手段，谋求教育创新，不断开拓，努力探索教育改革的新模式。公司秉承“品质、创新、服务”的理念，通过不断的产品创新与模式创新来提高教师的专业水平与学生的实战能力。</span>
    </div>
    <div class="tab-detail-text" style="margin-top: 30px">
      <span>自成立以来，一课教育联合全国战略合作伙伴已为600多所院校提供服务，服务内容包括：课程共建、教材出版、电子商务专业建设、产教融合基地加上、现代学徒制建设、校企合作、师资培训、技能大赛等。我们希望通过技术与模式的创新，以及对价值观的充分运用，更好地服务全国的职业院校，成为职业教育最好的供应链平台，服务数以万计的教育机构。</span>
    </div>
    <div class="course-system">
      <div class="img-box">
        <img src="./../../assets/images/about/course-system.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "yiKeIntro"
}
</script>

<style scoped lang="scss">
.tab-detail{
  display: flex;
  flex-direction: column;
  height: 100%;
  .tab-detail-title{
    margin-top: 60px;
    color: #222222;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
  }
  .tab-detail-text{
    text-indent:2em;
    span{
      color: #222222;
      font-size: 16px;
      font-weight: 300;
      line-height: 30px;
    }
  }
  .course-system{
    margin-top: 86px;
    display: flex;
    justify-content: center;
    margin-bottom: 78px;
    .img-box{
      width: 683px;
      height: 438px;
      img{
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
</style>