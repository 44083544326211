<template>
  <el-scrollbar class="about-yiKe">
    <div class="about-banner">
    </div>
    <div class="about-menu" id="aboutMenu">
      <div class="menu-item-box">
        <div  @click="selectTab(item.id)" v-for="item in tabList" :key="item.id"  :class="tabIndex === item.id ? 'about-menu-item menu-active' : 'about-menu-item'">
          <span>{{item.name}}</span>
        </div>
      </div>
    </div>
    <div :class="className">
      <div class="about-content">
        <yiKeIntro v-if="tabIndex === 1" />
        <YiKeCourse v-if="tabIndex === 2" />
        <YiKeHonor v-if="tabIndex === 3" />
        <ContactUs v-if="tabIndex === 4" />
      </div>
    </div>
    <OfficialFooter @amendTabIndex="amendTabIndex" />
  </el-scrollbar>
</template>

<script>
import YiKeIntro from '@/components/about/YiKeIntro'
import YiKeCourse from '@/components/about/YiKeCourse'
import YiKeHonor from '@/components/about/YiKeHonor'
import ContactUs from '@/components/about/ContactUs'
import OfficialFooter from '@/components/OfficialFooter'
export default {
  name: "AboutIndex",
  components:{
    YiKeIntro,
    YiKeCourse,
    YiKeHonor,
    ContactUs,
    OfficialFooter
  },
  data(){
    return {
      tabIndex:1,
      tabList:[
        {
          id:1,
          name:'一课简介'
        },
        {
          id:2,
          name:'一课历程'
        },
        {
          id:3,
          name:'一课荣誉'
        },
        {
          id:4,
          name:'联系我们'
        },
      ]
    }
  },
  mounted() {
    if(this.$route.query.contactUs){
      this.$nextTick(()=>{
        this.tabIndex = Number(this.$route.query.contactUs);
        this.$el.querySelector(`#aboutMenu`).scrollIntoView({
          behavior: "smooth",  // 平滑过渡
          block:    "start"  // 上边框与视窗顶部平齐。默认值
        });
      })
    }
  },
  computed:{
    className(){
      let name = '';
      switch (this.tabIndex){
        case 1:
          name = 'about-content-bj-one';
          break;
        case 2:
          name = 'about-content-bj-two';
          break;
        case 3:
          name = 'about-content-bj-three';
          break;
        default:
          name = 'about-content-bj'
      }
      return name;
    }
  },
  methods:{
    selectTab(num){
      this.tabIndex = num
    },
    amendTabIndex(){
      this.tabIndex = 4;
      this.$el.querySelector(`#aboutMenu`).scrollIntoView({
        behavior: "smooth",  // 平滑过渡
        block:    "start"  // 上边框与视窗顶部平齐。默认值
      });
    }
  }
}
</script>

<style scoped lang="scss">
.about-yiKe{
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  ::v-deep.el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .about-banner{
    width: 100%;
    height: 476px;
    background: url("./../../../assets/images/about/banner.jpg") no-repeat;
    background-size: cover;
  }
  .about-menu{
    display: flex;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px 1px rgba(62, 58, 234, 0.1);
    position:relative;
    .menu-item-box{
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      .about-menu-item{
        line-height: 90px;
        span{
          font-size: 18px;
          color: #222222;
          font-weight: 300;
          cursor: pointer;
        }
      }
      .menu-active{
        position: relative;
        span{
          color: #584EEE;
        }
        &:after{
          content: '';
          width: 40px;
          height: 4px;
          background: #584EEE;
          border-radius: 4px 4px 0px 0px;
          position: absolute;
          bottom: 0;
          left:50%;
          margin-left: -20px;
        }
      }
    }
  }
    .about-content{
      width: 1200px;
      margin: 0 auto;
    }
  .about-content-bj{
    display: flex;
    background-color: #fff;
  }
  .about-content-bj-one{
    background-color: #fff;
    display: flex;
    background-image: url('./../../../assets/images/about/lift-hand.jpg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 0 100%;
  }
  .about-content-bj-two{
    background-color: #fff;
    display: flex;
    background-image: url('./../../../assets/images/about/course-bj.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 100%;
  }
  .about-content-bj-three{
    background-color: #fff;
    display: flex;
    background-image: url('./../../../assets/images/about/honor-bj.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 100%;
  }
}
</style>