<template>
<div class="tab-detail">
  <div class="tab-detail-title">一课历程</div>
  <div class="tab-detail-content">
    <div class="detail-content-left">
      <img class="course-img" style="margin-top: 30px" src="./../../assets/images/about/course-1.png" alt="">
      <div class="year-num">2019</div>
      <div class="course-text">
        <span>与机械工业出版社联合出版教材</span>
        <span>与宁德技师学院达成合作办学</span>
        <span>通过国家高新技术企业认证</span>
        <span>通过IOS27001、IOS20000、IOS28001  等认证</span>
        <span>成为浙江省技工院校创业大赛协办单位</span>
        <div class="circle">
          <div class="circle-circle"></div>
        </div>
      </div>
      <img class="course-img" src="./../../assets/images/about/course-3.png" alt="">
      <div class="year-num">2017</div>
      <div class="course-text">
        <span>获得政府采购优秀供应商荣誉证书</span>
        <span>获得中国教学装备行业售后服务先进单位荣誉证书</span>
        <span>获得中国教学装备AAA级先进单位荣誉证书</span>
        <div class="circle">
          <div class="circle-circle"></div>
        </div>
      </div>
      <img class="course-img" src="./../../assets/images/about/course-5.png" alt="">
      <div class="year-num">2015</div>
      <div class="course-text">
        <span>公司正式成立并专注产品研发</span>
        <div class="circle">
          <div class="circle-circle"></div>
        </div>
      </div>
    </div>
    <div class="detail-content-mid"></div>
    <div class="detail-content-right">
      <div class="year-num" style="margin-top: 0">2020
      <div class="circle">
        <div class="circle-circle"></div>
      </div>
      </div>
      <div class="course-text">
        <span>通过软件企业认定</span>
        <span>成为京东首家全国战略合作伙伴</span>
        <span>成为第一届全国产教融合创业大赛协办单位</span>
        <span>一课教育新媒体矩阵号突破六百万粉丝</span>
        <span>与上海复旦大学出版社联合出版教材</span>
        <span>获得AAA级诚信供应商认证</span>
        <span>第五届江苏技能状元大赛技术支持单位</span>
      </div>
      <img class="course-img" src="./../../assets/images/about/course-2.png" alt="">
      <div class="year-num">2018
        <div class="circle">
          <div class="circle-circle"></div>
        </div>
      </div>
      <div class="course-text">
        <span>获得全国教育仿真软件产品认定荣誉证书</span>
        <span>获得全国科技成果转化优秀项目荣誉证书</span>
        <span>获得  AAA 级信用企业认证</span>
      </div>
      <img class="course-img" src="./../../assets/images/about/course-4.png" alt="">
      <div class="year-num">2016
        <div class="circle">
          <div class="circle-circle"></div>
        </div>
      </div>
      <div class="course-text">
        <span>取得多项作品登记证书</span>
        <span>取得多项计算机软件著作权</span>
      </div>
      <img class="course-img" src="./../../assets/images/about/course-6.png" alt="">
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "yiKeCourse",
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.tab-detail{
  display: flex;
  flex-direction: column;
  height: 100%;
  .tab-detail-title{
    margin-top: 60px;
    color: #222222;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
  }
  .tab-detail-content{
    margin-top: 64px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content:center;
    padding-bottom: 200px;
    .detail-content-left{
      display: flex;
      flex-direction: column;
      .course-text{
        position: relative;
        .circle{
          position: absolute;
          width: 26px;
          height: 26px;
          background: #fff;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          right: -53px;
          top: -48px;
          .circle-circle{
            width: 22px;
            height: 22px;
            background: #584EEE;
            border-radius: 50%;
          }
        }
      }
    }
    .detail-content-mid{
      width: 1px;
      background: #D2D2D2;
      height: calc(100% + 100px);
      margin: 0 40px;
    }
    .detail-content-right{
      .year-num{
        position: relative;
        .circle{
          position: absolute;
          width: 26px;
          height: 26px;
          background: #fff;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          left: -53px;
          top: -6px;
          .circle-circle{
            width: 22px;
            height: 22px;
            background: #584EEE;
            border-radius: 50%;
          }
        }
        //&:after{
        //  content: '';
        //  position: absolute;
        //  width: 24px;
        //  height: 24px;
        //  border-radius: 50%;
        //  background: #584EEE;
        //  left: -52px;
        //  top: -6px;
        //}
      }
    }
    .course-img{
      max-width: 528px;
      margin-top: 100px;
    }
    .year-num{
      color: #584EEE;
      font-size: 30px;
      font-weight: bold;
      line-height: 1;
      margin-top: 50px;
    }
    .course-text{
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      box-shadow: 0px 0px 21px 0px rgba(98, 95, 249, 0.05);
      border-radius: 4px 30px 4px 30px;
      padding: 30px 30px;
      span{
        color: #444;
        font-size: 18px;
        line-height: 28px;
        font-family: 'Microsoft YaHei UI Light';
      }
    }
  }
}

</style>