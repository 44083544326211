<template>
<div class="tab-detail">
  <div class="tab-detail-title">资质荣誉</div>
  <div class="tab-detail-content">
    <div class="detail-content-item">
      <div class="content-item-left">
        <div class="quadrangle"></div>
        <span class="text-overflow">获得软件企业认证</span>
      </div>
      <div class="content-item-right">
        <div class="quadrangle"></div>
        <span>获得AAA级质量服务信誉单位认证</span>
      </div>
    </div>
    <div class="detail-content-item">
      <div class="content-item-left">
        <div class="quadrangle"></div>
        <span>获得高新技术企业荣誉证书</span>
      </div>
      <div class="content-item-right">
        <div class="quadrangle"></div>
        <span>获得AAA级诚信经营示范单位认证</span>
      </div>
    </div>
    <div class="detail-content-item">
      <div class="content-item-left">
        <div class="quadrangle"></div>
        <span>获得政府采购优秀供应商荣誉证书</span>
      </div>
      <div class="content-item-right">
        <div class="quadrangle"></div>
        <span>获得AAA级资信企业认证</span>
      </div>
    </div>
    <div class="detail-content-item">
      <div class="content-item-left">
        <div class="quadrangle"></div>
        <span>获得中国教学装备行业售后服务先进单位荣誉证书</span>
      </div>
      <div class="content-item-right">
        <div class="quadrangle"></div>
        <span>获得AAA级重合同守信用企业认证</span>
      </div>
    </div>
    <div class="detail-content-item">
      <div class="content-item-left">
        <div class="quadrangle"></div>
        <span>通过IOS24001环境管理体系认证</span>
      </div>
      <div class="content-item-right">
        <div class="quadrangle"></div>
        <span>获得AAA级信用企业认证</span>
      </div>
    </div>
    <div class="detail-content-item">
      <div class="content-item-left">
        <div class="quadrangle"></div>
        <span>通过IOS9001质量管理体系认证</span>
      </div>
      <div class="content-item-right">
        <div class="quadrangle"></div>
        <span>获得AAA级诚信供应商认证</span>
      </div>
    </div>
    <div class="detail-content-item">
      <div class="content-item-left">
        <div class="quadrangle"></div>
        <span>通过IOS27001信息安全管理体系认证</span>
      </div>
      <div class="content-item-right">
        <div class="quadrangle"></div>
        <span>近百项作品著作权和计算机软件著作权</span>
      </div>
    </div>
    <div class="detail-content-item">
      <div class="content-item-left">
        <div class="quadrangle"></div>
        <span>通过IOS20000信息技术服务管理体系认证</span>
      </div>
      <div class="content-item-right">
        <div class="quadrangle"></div>
        <span>获得全国教育仿真软件产品认定企业荣誉证书</span>
      </div>
    </div>
    <div class="detail-content-item">
      <div class="content-item-left">
        <div class="quadrangle"></div>
        <span>通过IOS28001职业健康安全管理体系认证</span>
      </div>
      <div class="content-item-right">
        <div class="quadrangle"></div>
        <span>获得全国科技成果转化有修项目荣誉证书</span>
      </div>
    </div>
  </div>
  <div class="tab-detail-certificate">
    <div class="certificate-top">
      <img src="./../../assets/images/about/zhengshu1.png" alt="">
      <img src="./../../assets/images/about/zhengshu2.png" alt="">
      <img src="./../../assets/images/about/zhengshu3.png" alt="">
    </div>
    <div class="certificate-bottom">
      <img src="./../../assets/images/about/zhengshu4.png" alt="">
      <img src="./../../assets/images/about/zhengshu5.png" alt="">
      <img src="./../../assets/images/about/zhengshu6.png" alt="">
    </div>
  </div>
</div>
</template>

<script>
export default {
name: "yiKeHonor"
}
</script>

<style scoped lang="scss">
.tab-detail{
  display: flex;
  flex-direction: column;
  height: 100%;
  .tab-detail-title{
    margin-top: 60px;
    color: #222222;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
  }
  .tab-detail-content{
    display: flex;
    flex-direction: column;
    .detail-content-item{
      margin-top: 26px;
      line-height: 1;
      display: flex;
      &:nth-child(1){
        margin-top: 114px;
      }
      .content-item-left{
        display: flex;
        align-items: center;
        width: 50%;
      }
      .content-item-right{
        display: flex;
        align-items: center;
        width: 50%;
      }
      span{
        color: #444;
        font-size: 18px;
        font-family: 'Microsoft YaHei UI Light';
      }
      .quadrangle{
        width: 12px;
        height: 12px;
        border: 3px solid #F6BD10;
        transform: rotate(45deg);
        margin-right: 20px;
      }
    }
  }
  .tab-detail-certificate{
    margin: 94px 0 160px 0;
    display: flex;
    flex-direction: column;
    .certificate-top, .certificate-bottom{
      display: flex;
      justify-content: space-between;
    }
    .certificate-bottom{
      margin-top: 60px;
    }
  }
}
</style>